<template>
  <div>
    <b-card class="text-center" v-if="word">
      <template slot="header">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            flex-grow-1
            w-100
          "
        >
          <div style="position: absolute; right: 5px">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="text-body align-middle"
                />
              </template>
              <b-dropdown-item @click.stop="startUpdateWord">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click.stop="ensureDelete">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <div v-if="!$attrs.hideWordImg">
        <b-img
          width="100px"
          :src="word.image.value"
          v-if="word.image.value"
        />
        <b-img
          v-else
          :blank="true"
          blank-color="#ccc"
          alt="placeholder"
          width="100px"
        />
      </div>
      
      <h3 class="mt-1 mb-0 text-truncate-2">{{ word.title }} <span v-if="!$attrs.hideWordType">({{ word.word_type }})</span></h3>
    </b-card>
    <word-create-modal
      @updateWord="updateWord"
      ref="modalWord"
    />
  </div>
</template>

<script>
import WordCreateModal from "./WordCreateModal";
export default {
  components: {
    WordCreateModal,
  },
  props: {
    index: {type: Number, required: true},
    word: { type: Object, default: null}
  },
  methods: {
    ensureDelete() {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.$emit("confirmDelete", this.index);
          }
        });
    },
    startUpdateWord() {
      this.$refs.modalWord.openUpdateModal(this.word, this.index);
    },
    updateWord({ word, index }) {
      this.word = word;
      this.$emit("wordUpdated",{ word, index });
    }
  },
};
</script>
