export default {
  milestone: null,
  image: {
    type: 'image',
    value: null,
    meta: {
      alt: null,
      redirect_link: null,
    },
  },
  description: {
    type: 'translatable-text',
    meta: {
      default_lang: 'vi'
    },
    value: '',
    translations: [
      {
        language: 'vi',
        value: ''
      }
    ]
  },
  audio: {
    type: 'audio',
    value: null,
    meta: {
      alt: null,
      redirect_link: null,
    },
  },
  title: null,
  word_type: null,
  sub: {
    type: 'translatable-text',
    meta: {
      default_lang: 'vi'
    },
    value: '',
    translations: [
      {
        language: 'vi',
        value: ''
      }
    ]
  },
  spelling: null,
  flip: false
}
