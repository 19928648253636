<template>
  <div v-if="isOpen">
    <b-modal
      centered
      title="Create Word"
      v-model="isOpen"
      id="modal-xl"
      size="xl"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <b-row>
          <b-col cols="3">
            <strong class="h6">Image</strong>
            <ui-component :component-data="word.image" class="border" />
          </b-col>
          <b-col cols="9">
            <b-col cols="12">
              <b-form-group label="Full data" label-for="v-status">
                <div class="d-flex">
                  <b-form-checkbox
                    class="custom-control-primary"
                    name="status_checkbox"
                    switch
                    v-model="word.status"
                    unchecked-value="2"
                    value="1"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckCircleIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XCircleIcon" />
                    </span>
                  </b-form-checkbox>
                  <strong>{{ word.status == 1 ? "On" : "Off" }}</strong>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Title" label-for="v-title">
                <b-input v-model="word.title" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Word Type" label-for="v-word-type">
                <b-input v-model="word.word_type" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Spelling" label-for="v-spelling">
                <b-input v-model="word.spelling" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Translation" label-for="v-subtitle">
                <ui-component :component-data="word.sub" class="border" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description" label-for="v-description">
                <ui-component :component-data="word.description" class="border" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Audio" label-for="v-audio">
                <ui-component :component-data="word.audio" class="border" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Flip" label-for="v-flip">
                <div class="d-flex">
                  <b-form-checkbox
                    class="custom-control-primary"
                    name="is_publish_checkbox"
                    switch
                    v-model="word.flip"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckCircleIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XCircleIcon" />
                    </span>
                  </b-form-checkbox>
                  <strong>{{ word.flip ? "Yes" : "No" }}</strong>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Related Lessons" label-for="relate-stages">
                <div class="border">
                  <b-card class="mb-0">
                    <div v-for="stage in relateStages" :key="stage._id">
                      <b-link
                        :to="{
                          name: 'learning_lesson-detail',
                          params: { id: stage._id },
                        }"
                        :id="`stage_${ stage.display_order + 1}`"
                      >
                        {{ transformTranslatableText(stage.path_id.title, authLanguage) }} - {{ lessonType[stage.type] }} - {{ transformTranslatableText(stage.name, authLanguage) }}
                      </b-link>
                    </div>
                  </b-card>
                </div>
              </b-form-group>
            </b-col>
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button type="submit" variant="primary" @click="save">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {transformTranslatableText} from '@core/utils/index'
import defaultObject from './defaultObject'
import service from '../../service'
export default {
  data() {
    return {
      word: null,
      isOpen: false,
      wordIndex: null,
      isUpdate: false,
      relateStages: [],
      lessonType: null
    };
  },
  computed: {
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  methods: {
    openCreateModal() {
      this.word = JSON.parse(JSON.stringify(defaultObject));
      this.isOpen = true;
      this.isUpdate = false;
    },
    async openUpdateModal(word, index){
      this.word = word;
      this.wordIndex = index;
      this.isOpen = true;
      this.isUpdate = true;
      let relateStagesResponse = await this.getRelateStages(word._id)
      if (relateStagesResponse){
        this.relateStages = relateStagesResponse.document;
        this.lessonType = relateStagesResponse.meta['LESSON_TYPE_STRING'];
      }
    },
    async getRelateStages(id) {
      return await service.getRelateStages({ id })
    },
    async save(){
      this.isOpen = true;
      this.word.milestone = 1;
      if(!this.isUpdate){
        let response = await service.create(this.word);
        if (response.data.type === "DATA") {
          this.$emit("addWord", response.data.data.new_document);
          this.$store.dispatch("pushSuccessNotify", {
            text: "Word created!",
          });
        } else {
          this.$store.dispatch("pushErrorNotify", {
            text: "Error created word!",
          });
        }
      }
      else{
        await service.update({ data: JSON.stringify(this.word) });
        this.$emit("updateWord",{ word:this.word, index: this.wordIndex})
      }
      this.wordIndex = null;
      this.isOpen = false;
    },
    cancel(){
      this.wordIndex = null;
      this.isOpen = false;
      this.isUpdate = false;
      this.relateStages = [];
    },
    transformTranslatableText
  },
};
</script>
