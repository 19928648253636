<template>
  <div class="page-content">
    <page-breadcrumb title="Word Pool" />
    <b-row class="mb-2">
      <b-col class="col-2">
        <b-form-group label="Title" label-for="title">
          <b-input v-model="filter.title" type="text" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        class="col-lg-3 col-md-6 col-12"
        v-for="(word,index) in words"
        :key="`${word}_${index}`"
      >
        <word-card
          :word="word"
          :index="index"
          @confirmDelete="confirmDelete"
          @updateWord="openUpdateModal"
          ref="word_card"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center mt-1" cols="12">
        <b-button class="ml-2" variant="warning" @click="openCreateModal">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Create new word</span>
        </b-button>
      </b-col>
    </b-row>
    <word-create-modal
      @addWord="addWord"
      ref="modalWord"
    />
    <b-pagination
      class="mt-2"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import WordCard from "./_components/WordCard.vue";
import WordCreateModal from './_components/WordCreateModal.vue'
import service from '../service'
export default {
  components: {
    WordCard,
    WordCreateModal
  },
  data() {
    return {
      words: [],
      filter:{},
      current_page: 1,
      total_items: 0,
      items_perpage: 15,
    };
  },
  watch:{
    filter: {
      handler(){
        this.getWords()
      },
      deep: true
    },
    current_page() {
      this.getWords();
    },
  },
  created(){
    this.getWords()
  },
  methods: {
    addWord() {
      this.getWords()
    },
    openCreateModal(){
      this.$refs.modalWord.openCreateModal()
    },
    openUpdateModal(updateData){
      this.$refs.modalWord.openUpdateModal(updateData.word, updateData.index)
    },
    confirmDelete(index) {
      this.deleteWord(index);
    },
    async deleteWord(index){
      let response = await service.delete({id: this.words[index]._id});
      if (response.data.type == "SUCCESS"){
        this.words.splice(index, 1);
      }
    },
    async getWords(){
      let query = this.filter
      if (this.filter.title){
        query = {
          title: { $regex: this.filter.title, '$options' : 'i' }
        }
      }
      let response = await service.getIds({
        page: this.current_page,
        limit: this.items_perpage,
        query: query
      })
      if (response){
        // this.words = response.data.data.list;
        let wordResponse = await service.getList({query:{_id:{$in:response.data.data.list}}});
        this.words = wordResponse.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
  },
};
</script>
